@import '../../styles/global.scss';

.icon-link {
    @include body-regular;
    @include link-rm-style;
    color: $text;
    padding: $spacing-12;
    margin: 0;
    background: none;
    border: none;
    outline: none;
    cursor: pointer;
    position: relative;

    &:hover {
        text-decoration: none;
        opacity: 0.8;
    }

    &__wrapper {
        display: inline-block;
        position: relative;
    }

    &__tooltip {
        @include desktop{
            visibility: hidden;
            width: to-rem(120);
            background-color: $background-blue;
            color: white;
            text-align: center;
            border-radius: to-rem(4);
            padding: $spacing-4 0;
            position: absolute;
            bottom: 125%;  
            left: 50%;
            transform: translateX(-50%);
            opacity: 0;
            transition: opacity 0.3s ease-in-out;
            font-size: 12px;
            white-space: nowrap;
            pointer-events: none; 
        }
    }

    &__wrapper:hover .icon-link__tooltip {
        @include desktop{
            visibility: visible;
            opacity: 1;
        }
    }
}
@import '../../styles/global.scss';

.banner{
    color: $text-invert;
    @include mobile-section-container;

    &--blue{
        background-color: $background-blue;
    }

    &--dark{
        background-color: $background-dark-grey;
    }

    @include desktop{
        display: flex;
        justify-content: center;
        padding: $spacing-64 $spacing-160;
        gap: $spacing-32;
        justify-content: center;
        flex-direction: column;
    }

    @include tablet{
        justify-content: center;
        @include tablet-section-container;
        flex-direction: column;
    }

    &__section-wrapper{
        display: flex;
        flex-direction: column;
        gap: $spacing-16;

        @include desktop{
            gap: $spacing-24;
        }
    }

    &__section-upper{
        display: flex;
        flex-direction: column;
        gap: $spacing-8;

        &--body{
            text-align: left;
        }
    }

}
@import '../../styles/global.scss';

.panel-display {
    display: flex;
    flex-direction: column-reverse;
    gap: $spacing-16;

    @include desktop {
        flex-direction: row;
        flex: 1;
        gap: $spacing-48;
    }

    @include tablet {
        flex-direction: row;
        flex: 1;
    }

    &--reverse {
        @include desktop {
            flex-direction: row-reverse;
        }

        @include tablet {
            flex-direction: row-reverse;
        }
    }

    &__text-wrapper {
        display: flex;
        flex-direction: column;
        gap: $spacing-8;

        @include desktop {
            max-width: 50%;
            justify-content: center;
        }

        @include tablet {
            max-width: 50%;
            justify-content: center;
        }

        &--header {
            text-align: left;
        }

        &--body {
            text-align: left;
        }
    }

    &__image {
        width: 100%;
        border-radius: to-rem(8);
        margin: auto;
        transition: opacity 0.3s ease-in-out;  

        @include desktop {
            border-radius: to-rem(16);
            max-width: 50%;
        }

        @include tablet {
            border-radius: to-rem(14);
            max-width: 50%;
        }

        &.fade {
            opacity: 0;
        }
    }
}
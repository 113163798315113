@import '../../styles/global.scss';

.full-width-image-section{
    background-color: $background-grey;

    &__section-wrapper{
        @include desktop{
            @include desktop-section-container;
            flex-direction: column;
        }
    
        @include tablet{
            @include tablet-section-container;
            flex-direction: column;
        }
    }

    &__text-wrapper{
        display: flex;
        flex-direction: column;
        display: flex;
        padding: $spacing-32 $spacing-32 $spacing-16 $spacing-32;
        gap: $spacing-8;

        @include desktop{
            padding: 0;
        }

        &--header{
            text-align: center;
        }

        &--body{
            text-align: center;
        }
    }

    &__image-wrapper{
        width: 100%;
        display: flex;
        padding-bottom: $spacing-32;

        @include desktop{
            display: flex;
            justify-content: center;
            margin: auto;
            width: 100%;
            padding-bottom: $spacing-0;
        }

        @include tablet{
            padding-bottom: $spacing-0;
        }

        &--image{
            width: 100%;

            @include desktop{
                width: 100%;
            }
        }
    }
}
@import '../../styles/global.scss';

.card {
    display: flex;
    flex-direction: column;
    gap: $spacing-8;
    padding: $spacing-24;
    border-radius: $mobile-radius;
    background-color: $background-grey;
    border: 1px solid $divider;
    box-sizing: border-box;
    min-width: to-rem(320);

    @include desktop {
        padding: $spacing-32;
        border-radius: $desktop-radius;
        max-width: unset;  
    }

    @include tablet {
        border-radius: $tablet-radius;
    }

    &__text {
        text-align: left;
        overflow-wrap: break-word;  
    }

    &__icon {
        padding: $spacing-8 0;
        color: $background-blue;
    }

    &--80 {
        @include desktop {
            width: calc(50% - $spacing-16);  
        }
    }

    &--20 {
        @include desktop {
            width: calc(33.33% - $spacing-16);  
        }
    }
}
@import './partials/mixins';
@import './partials/variable';
@import './partials/function';

@font-face {
    font-family:'Lufga';
    src: url(../assets/fonts/Lufga-Light.otf)format('opentype');
    font-weight: 400;
}

@font-face {
    font-family:'Lufga';
    src: url(../assets/fonts/Lufga-Medium.otf) format('opentype');
    font-weight: 600;
}

@font-face {
    font-family: 'Lufga';
    src: url(../assets/fonts/Lufga-Bold.otf) format('opentype');
    font-weight: 700;
}

body{
    font-family: "Poppins", sans-serif;
    background-color: white;
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    color: $text;
}

a{
    text-decoration: none;
    color: $text;
    opacity: 1;

    &:hover{
        text-decoration: none;
    }
}

h1{
    @include h1;
}

h2{
    @include h2;
}

h3{
    @include h3;
}

h4{
    @include h4;
}

.body-big{
    @include body-big;
}

p{
    @include body-regular;
}

.body-small{
    @include body-small;
}

.link-rm-style{
    text-decoration: none;
    color: inherit;
    opacity: 1;
}

.page-layout{
    display: flex;
    flex-direction: column;
    padding-top: 5rem;

    @include desktop{
        display: grid;
        grid-auto-flow: column;
        grid-template-columns: 260px 18fr;
        // max-width: 1600px;
        min-height: 100vh;
        padding: 0;
    }
}

.page-layout-main{
    @include desktop{
        position: relative;
    }
}

.drag{
    position: absolute;
    z-index: 50;

    @include desktop{
        display: none;
    }

    @include tablet{
        display: none;
    }
}
@import '../../styles/global.scss';

.card-tile{
    color: $text;
    padding: $spacing-16;
    border-radius: 0.5rem;
    background-color: $background-invert;
    box-shadow: 0px 4px 48px 0px rgba(0, 0, 0, 0.05);
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: $spacing-12;

    @include desktop{
        display: flex;
        flex-direction: column;
        gap: $spacing-12;
        padding: $spacing-24;
    }

    &__text-wrapper{
        display: flex;
        flex-direction: column;
        gap: $spacing-4;

        &--body{
            text-align: left;
            @include body-small;
        }
    }

    &__image-wrapper{
        display: flex;
    }
    
    &__image{
        width: 100%;
        border-radius: 0.25rem;
    }

    &:hover{

        @include desktop{
            transform: translateY(-16px);
            transition: 150ms;
        }
    }
}
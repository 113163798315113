@import '../../styles/global.scss';

.wave-emoji {
    display: inline-block;
    animation: wave 2s infinite ease-in-out;
}

@keyframes wave {
    0% { transform: rotate(0deg); }
    20% { transform: rotate(15deg); }
    40% { transform: rotate(-10deg); }
    60% { transform: rotate(10deg); }
    80% { transform: rotate(-5deg); }
    100% { transform: rotate(0deg); }
}
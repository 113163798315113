@import '../../styles/global.scss';

.game-list{
    display: flex;
    gap: $spacing-16;
    align-items: left;
    align-items: center;

    &__image{
        width: to-rem(64);
        border-radius: to-rem(8);
    }

    &__title{
        font-style: italic;
        font-weight: 700;
    }

    @include desktop{
        flex-direction: row;
        gap: $spacing-16;
        align-items: center;
    }
}
@import '../../styles/global.scss';

.table-list-section{
    background-color: $background-grey;

    &__section-wrapper{
        @include mobile-section-container;

        @include desktop{
            @include desktop-section-container;
        }
    
        @include tablet{
            @include tablet-section-container;
        }
    }

    &__table-wrapper{
        display: flex;
        flex-direction: column;
        gap: $spacing-8;

        &--header{
            text-align: center;
        }

        &--body{
            text-align: center;
        }
    }

    &__section-container{
        margin: auto;
        display: flex;
        flex-direction: column;
        padding: $spacing-32 $spacing-32 $spacing-24 $spacing-32;
        gap: $spacing-8;
    }
}
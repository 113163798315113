@import '../../styles/global.scss';

.button{
    padding: 0 to-rem(24);
    border-radius:  to-rem(8);
    width: stretch;
    justify-content: center;
    text-align: center;
    display: flex;
    text-decoration: none;
    align-items: center;

    &--primary{
        background: $button-default;
        color: $text-invert;

        &:hover{
            background: $button-hover;
        }
    
        &:active{
            background: $button-active;
        }
    }

    &--secondary{
        border: 1.5px solid $button-default;
        color: $button-default;

        &:hover{
            border-color: $button-hover;
            color: $button-hover;
        }
    
        &:active{
            border-color: $button-active;
            color: $button-active;
        }
    }

    &--ghost{
        background: rgba(0, 0, 0, 0.10);

        &:hover{
            background: rgba(0, 0, 0, 0.20);
        }
    
        &:active{
            background: rgba(0, 0, 0, 0.30);
        }
    }

    &__default{
        height: to-rem(48);

        @include desktop{
            height: to-rem(56);
        }

        @include tablet{
            height: to-rem(56);
        }
    }

    @include desktop{
        width: auto;
        display: inline-flex;
        width: fit-content;
    }

    @include tablet{
        width: auto;
        display: inline-flex;
        width: fit-content;
    }

    &__text{
        width: auto;
        font-weight: 600;
        white-space: nowrap;
        text-decoration: none;
    }

    &__full-width{
        width: -webkit-fill-available;
        text-align: center;
    }
}
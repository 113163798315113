@import "../../styles/global.scss";

.hero {
    @include mobile-section-container-no-bottom-spacing;

    @include desktop {
        @include desktop-section-container-no-bottom-spacing;
        flex-direction: column;
        gap: $spacing-48;
    }

    @include tablet {
        @include tablet-section-container-no-bottom-spacing;
    }

    &--stack {
        @include mobile-section-container;

        @include desktop {
            display: flex;
            justify-content: center;
            padding: $spacing-48 $spacing-120;
            gap: $spacing-32;
        }

        @include tablet {
            @include tablet-section-container;
        }
    }

    &__section-container {
        margin: auto;
        width: 100%;
        z-index: 1;
        display: flex;
        flex-direction: column;
        gap: $spacing-32;

        &--stack {
            display: flex;
            gap: $spacing-16;
            flex-direction: column-reverse;

            @include desktop {
                flex-direction: row;
                gap: $spacing-48;
            }

            @include tablet {
                padding: 0;
                flex-direction: row;
            }
        }
    }

    &__text-wrapper {
        display: flex;
        flex-direction: column;
        gap: $spacing-8;

        @include desktop {
            gap: $spacing-16;
            @include desktop-text-wrapper;
        }

        &--stack {
            @include desktop {
                gap: $spacing-16;
                padding: 0;
                justify-content: center;
            }
        }
    }

    &__text-wrapper-header {
        text-align: left;

        @include desktop{
            text-align: center;
        }
        
        @include tablet{
            text-align: center;
        }

        &--stack {
            text-align: left;
        }
    }

    &__text-wrapper-body {
        text-align: left;

        @include desktop{
            text-align: center;
        }

        @include tablet{
            text-align: center;
        }

        &--stack {
            text-align: left;
        }
    }

    &__image {
        width: 100%;
        transition: opacity 0.3s ease-in-out;
        margin: auto;

        &.fade {
            opacity: 0;
        }

        @include desktop {
            width: 80%;
        }

        @include tablet {
            width: 80%;
        }

        &--stack {
            border-radius: $mobile-radius;
            width: 100%;

            @include desktop{
                width: 50%;
                border-radius: $desktop-radius;
            }

            @include tablet{
                width: 50%;
                border-radius: $tablet-radius;
            }
        }
    }
}
@import '../../styles/global.scss';

.table-list{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    border-bottom: 1px solid $divider;
    padding-bottom: $spacing-16;

    &__text{
        text-align: right;
    }
}
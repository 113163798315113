@import "../../styles/global.scss";

.ticker {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: $background-grey;
    overflow: hidden;
    width: 100%;
    position: relative;
    padding: $spacing-32 0 $spacing-64 0;
    gap: $spacing-16;

    @include desktop {
        align-items: center;
        gap: $spacing-32;
        padding: $spacing-64 0 $spacing-128 0;
    }

    &__text-wrapper {
        display: flex;
        gap: $spacing-16;
        flex-direction: column;
        text-align: center;

        @include desktop {
            padding: 0;
        }

        @include tablet {
            @include tablet-section-container;
        }
    }

    &__image-container {
        width: 100%;
        overflow: hidden;
        position: relative;
        display: flex;

        @media screen and (max-width: $mobile-breakpoint) {
            -webkit-mask-image: linear-gradient(
                to right,
                rgba(0, 0, 0, 0) 0%,
                rgba(0, 0, 0, 1) 20%,
                rgba(0, 0, 0, 1) 80%,
                rgba(0, 0, 0, 0) 100%
            );
            mask-image: linear-gradient(
                to right,
                rgba(0, 0, 0, 0) 0%,
                rgba(0, 0, 0, 1) 20%,
                rgba(0, 0, 0, 1) 80%,
                rgba(0, 0, 0, 0) 100%
            );
        }
    }

    &__image-wrapper {
        display: flex;
        flex-wrap: nowrap;
        gap: $spacing-56;
        min-width: 200%; 
        will-change: transform;

        @include desktop{
            width: 100%; 
            overflow: hidden;
            justify-content: center;
            min-width: 0;
            padding: 0 $spacing-120;
        }

        @include tablet{
            width: 100%; 
            overflow: hidden;
            flex-wrap: wrap;
            min-width: 0;
            padding: 0 $spacing-80;
            justify-content: center;
        }

        &--animated {
            display: flex;
            width: max-content;
            animation: slide-left 30s linear infinite;
        
            @include desktop{
                animation: none;
            }
        
            @include tablet{
                animation: none;
            }
        }
    }

    &__image {
        height: to-rem(56);
        flex-shrink: 0;

        @include desktop{
            height: auto;
            width: 18%;
        }
    }


    @keyframes slide-left {
        from {
            transform: translateX(50%);  
        }
        to {
            transform: translateX(-250%);  
        }
    }
}
@import '../../styles/global.scss';

.logo{
    display: flex;
    justify-content: flex-start;
    align-items: center;

    @include desktop{
        width: 100%;
        justify-content: flex-start;
    }

    &__logo-itself{
        height: 2.5rem;
    }
}
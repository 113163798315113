// spacing tokens
$spacing-0: 0rem; 
$spacing-4: 0.25rem;
$spacing-8: 0.5rem;
$spacing-12: 0.75rem;
$spacing-16: 1rem; 
$spacing-24: 1.5rem;
$spacing-32: 2rem;
$spacing-40: 2.5rem;
$spacing-48: 3rem;
$spacing-56: 3.5rem;
$spacing-64: 4rem;
$spacing-80: 5rem;
$spacing-96: 6rem;
$spacing-104: 6.5rem;
$spacing-112: 7rem;
$spacing-120: 7.5rem;
$spacing-128: 8rem;
$spacing-140: 11.6rem;
$spacing-160: 10rem;

// font tokens
$font-12: 0.75rem;
$font-14: 0.875rem;
$font-16: 1rem; 
$font-18: 1.125rem; 
$font-20: 1.25rem; 
$font-24: 1.5rem;
$font-32: 2rem;
$font-40: 2.5rem;
$font-48: 3rem;
$font-56: 3.5rem;
$font-64: 4rem;
$font-80: 5rem;
$font-96: 6rem;

// border radius
$mobile-radius: 0.5rem;
$tablet-radius: 0.875rem;
$desktop-radius: 1rem;

// color tokens
$divider: #D8D8D8;
$text: #000000;
$text-invert: #ffffff;
$text-subtle: #696969;
$background-blue: #006AFF;
$background-invert: #ffffff;
$background-grey: #f8f8f8;
$background-green: #99DD29;
$background-dark-grey: #333131;
$background-gradient:linear-gradient(180deg, #FFF 0%, #F7F7F7 100%);
$icon-blue: #006AFF;
$button-default: #006AFF;
$button-hover: #0055CC;
$button-active: #004AB2;
$button-ghost-hover: red;
$button-ghost-active: red;
$text-blue: #006AFF;

// breakpoints
$desktop-breakpoint: 1024px;
$mobile-breakpoint: 640px;

//grids
$desktop-column-width: 3.9375rem;
$desktop-gutter-width: 1.5rem;
$desktop-column-count: 12;

$desktop-total-width: ($desktop-column-width * 11)+ ($desktop-gutter-width * 10);
$desktop-total-width-banner: ($desktop-column-width * 10)+ ($desktop-gutter-width * 9);
$desktop-total-width-form: ($desktop-column-width * 6)+ ($desktop-gutter-width * 5);

@import '../../styles/global.scss';

.emoji-move{
    width: 100vw;
    height: 100vh;
    position: relative;

    &__emoji{
        position: absolute;
        cursor: move;
        font-size: 2.5rem;
    }
}
@import '../../styles/global.scss';

@keyframes spin {
    0%{
       transform: rotate(0deg);
      }
   100%{
       transform: rotate(360deg);
      }
   }

.emoji-rotate{
    animation: spin 2s linear forwards;
}

body{
    background: black;
    color: white;
}

u
h1{
    font-family: "Pixelify Sans", sans-serif;
    font-optical-sizing: auto;
    font-style: normal;
    margin: 0;
    font-size: 2.75rem;
    line-height: 3rem;
    letter-spacing: 4px;
    text-align: left;

    @include desktop{
        text-align: center;
        margin: 0;
    }

    @include tablet{
        text-align: center;
        margin: 0;
    }
}

p{
    font-family: "Source Code Pro", monospace;
    font-optical-sizing: auto;
    font-style: normal;
    margin: 0;
    font-size: 0.875rem;
    line-height: 1.5rem;
    opacity: 80%;
    text-align: left;

    @include desktop{
        text-align: center;
        font-size: 1rem;
    }

    @include tablet{
        text-align: center;
        font-size: 1rem;
    }
}

a{
    color: white;
    opacity: 60%;
    text-decoration: none;

    &:hover{
        opacity: 100%;
        text-decoration: underline;
    }
}

.button{
    width: auto;
    display: flex;
    justify-content: left;
    align-items: center;

    @keyframes spin {
        0%{
           transform: rotate(0deg);
          }
       100%{
           transform: rotate(360deg);
          }
       }

    &__wrapper{
        background: transparent !important;
        color: transparent;
        border: none !important;
        box-shadow: none !important;    
        margin: 0;
        padding: 0;
        all: unset;
        font-size: 3.125rem;
        line-height: 3rem;
        padding-bottom: 0.5rem;
        text-align: left;
        width: auto;
        z-index: 10;
        color: white;
    

        @include desktop{
            text-align: center;
            font-size: 5rem;

            &:hover{
                animation: spin 2s linear infinite;
            }
        }

        @include tablet{
            text-align: center;
        }
    }
}

.main{
    font-family: "Pixelify Sans", sans-serif;
    display: flex;
    flex-direction: column;
    justify-content: center; 
    text-align: center;  
    height: 100vh;
    padding: 0 2rem;
    align-items: flex-start;
    gap: 1rem;

    @include desktop{
        padding: 0 20rem;
        text-align: center;
        align-items: center;
    }

    @include tablet{
        padding: 0 4rem;
        text-align: center;
        align-items: center;
    }

    &__heading{
        width: auto;
        text-align: left;
        margin: 0;
        font-size: 2rem;
        width: 100%;

        @include desktop{
            text-align: center;
            font-size: 3rem;
            margin: 0;
        }

        @include tablet{
            text-align: center;
            font-size: 2rem;
            margin: 0;
        }
    }

    &__image{
        display: flex;
        justify-content: center;
    }
}
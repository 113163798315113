@import '../../styles/global.scss';

.panel-section{
    background-color: $background-grey;
    border-top: 1px solid $divider;

    &__section-wrapper{
        @include mobile-section-container;
        gap: $spacing-32;

        @include desktop{
            @include desktop-section-container;
            flex-direction: column;
            gap: $spacing-64;
        }

        @include tablet{
            @include tablet-section-container;
        }

    }
}



@import '../../styles/global.scss';

.faq-item{
    display: flex;
    flex-direction: column;
    text-align: left;
    border-bottom: 1px solid $divider;

    @include desktop{
        padding: $spacing-32 0;
        gap: $spacing-16;
    }

    @include tablet{
        padding: $spacing-32 0;
        gap: $spacing-16;
    }

    &:last-child{
        border-bottom: none;
    }

    &__button{
        all: unset;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        gap: $spacing-16;  
        padding: $spacing-24 $spacing-8;
        border-radius: $mobile-radius;

        @include desktop{
            padding: $spacing-24 $spacing-16;
            cursor: pointer;
            border-radius: $desktop-radius;
        }

        @include tablet{
            border-radius: $desktop-radius;
        }
        
        &:hover{
            @include desktop{
                background-color: rgba(0, 0, 0, 0.042);
            }
        }
    }

    &__text{
        text-align: left;
        padding: 0 $spacing-12 $spacing-24 $spacing-8;

        @include desktop{
            padding: 0 $spacing-16;
        }
    }
}
@import '../../styles/global.scss';

.text-link {
    @include body-regular;
    @include link-rm-style;
    color: $text;
    padding: $spacing-12;
    font-weight: 600;
    margin: 0;
    cursor: pointer;

    &__inner {
        padding: $spacing-12;
        text-align: center;

        @include desktop {
            text-align: left;
        }

        &:hover {
            text-decoration: none;
            border-radius: $mobile-radius;

            @include desktop{
                background-color: rgba(0, 0, 0, 0.042);
            }
        }

        .text-link.active & {
            font-weight: bold;
            color: $text-blue;
            background-color: rgba(0, 0, 0, 0.042);
            border-radius: $mobile-radius;
        }
    }
}
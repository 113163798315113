@import '../../styles/global.scss';

.banner-promo{
    background-color: $background-grey;

    &__section-wrapper{
        @include mobile-section-container;

        @include desktop{
            @include desktop-section-container;
        }

        @include tablet{
            @include tablet-section-container;
        }
    }

    &__banner-wrapper{
        background-color: $background-green;
        border-radius: $mobile-radius;
        overflow: hidden;
        display: flex;
        flex-direction: column;

        @include desktop{
            flex-direction: row;
            border-radius: $desktop-radius;
        }

        @include tablet{
            border-radius: $tablet-radius;
        }
    }

    &__text-wrapper{
        display: flex;
        flex-direction: column;
        text-align: left;
        gap: $spacing-8;
    }

    &__left-wrapper{
        display: flex;
        flex-direction: column;
        padding: $spacing-24;
        gap: $spacing-16;

        @include desktop{
            gap: $spacing-24;
        }

        @include desktop{
            padding: $spacing-48;
            justify-content: center;
        }

        @include tablet{
            padding: $spacing-32;
        }
    }

    &__image-wrapper{
        display: flex;
        background-image: url("../../assets/images/plugin-image.png");

    }

    &__image{
        width: 100%;
    }

    &__body{
        text-align: left;
    }
}
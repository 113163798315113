@import '../../styles/global.scss';

.badge-desktop{

    display: none;

    @include desktop{
        padding: $spacing-8 $spacing-16;
        background: rgba(255, 255, 255, 0.10);
        display: inline;
        border-radius: 4px;
    }

    @include tablet{
        padding: $spacing-8 $spacing-16;
        background: rgba(255, 255, 255, 0.10);
        display: inline;
        border-radius: 4px;
    }

    &__text{
        &--divider{
            opacity: 0.5;
        }
    }

}

.badge-mobile{

    @include desktop{
        display: none;
    }

    @include tablet{
        display: none;
    }

    &__wrapper{
        padding: $spacing-8 $spacing-16;
        background: rgba(255, 255, 255, 0.10);
        display: inline-block;
        border-radius: 4px;

        &--margin-bottom{
            padding: $spacing-8 $spacing-16;
            background: rgba(255, 255, 255, 0.10);
            display: inline-block;
            border-radius: 4px;
            margin-bottom: $spacing-16;
        }
    }
}
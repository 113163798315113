@import '../../styles/global.scss';

.cards-composition {
    background: $background-gradient;
    position: relative;  

    &__section-wrapper {
        @include mobile-section-container;
        display: flex;
        flex-direction: row;
        gap: 1rem;
        overflow-x: auto;
        scroll-padding-left: 1.5rem;
        scroll-snap-type: x mandatory;
        scrollbar-width: none;
        position: relative; 
        -webkit-mask-image: linear-gradient(to right, rgba(0, 0, 0, 1) 80%, rgba(0, 0, 0, 0));
        mask-image: linear-gradient(to right, rgba(0, 0, 0, 1) 80%, rgba(0, 0, 0, 0));

        @include desktop {
            flex-wrap: wrap;
            flex-direction: row;
            overflow-x: unset;
            white-space: normal;
            justify-content: center;
            padding: $spacing-48 $spacing-120;
            gap: $spacing-32;
            margin: auto;
            -webkit-mask-image: none;  
            mask-image: none;
        }

        @include tablet {
            @include tablet-section-container;
            flex-wrap: wrap;
            flex-direction: row;
            overflow-x: unset;
            white-space: normal;
            -webkit-mask-image: none;  
            mask-image: none;
        }
    }
}